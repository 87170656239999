import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  
    <Switch>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./dashboard'))}/>
      <Route path={`${match.url}submission`} component={asyncComponent(() => import('./submission'))}/>
      <Route path={`${match.url}model/:model/:view/:id`} component={asyncComponent(() => import('./Model'))}/>
      <Route path={`${match.url}model/:model/:view`} component={asyncComponent(() => import('./Model'))}/>

    </Switch>
  
);

export default App;
