import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import Api from '../../Api'
class UserProfile extends Component {

  state={data:{}}

  componentWillUnmount() {
      Api.RemoveSoket('notificacion/'+this.state.id)
  }


  componentWillMount() {
    var user = localStorage.getItem('user_id')
    

    Api.UserInfo().then((data) => {
        
        this.setState({data:data.data});
        
        
        Api.Soket('notificacion/'+data.data.id,(sms)=>{
          
              var options = {
              body: sms.de_user.name+':'+sms.name,
              icon: Api.getImg('users','imagen',data.data.id),
              dir: "ltr"
            };
            var notification = new Notification(sms.de_user.name, options);
        })  

        Notification.requestPermission();

      });


  }
  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li>Connections</li>
        <li onClick={() => this.props.userSignOut()}>Salir</li>
      </ul>
    );

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={'data:image/jpeg;base64,'+this.state.data.imagen}
                  className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          <span className="gx-avatar-name">{this.state.data.name}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        </Popover>
      </div>

    )

  }
}

export default connect(null, {userSignOut})(UserProfile);
