import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import Parse from 'parse';
import Api from '../../Api'

const SubMenu = Menu.SubMenu;


class SidebarContent extends Component {

  state={load:true,data:{}}
  componentWillMount() {


    Api.Menu().then((data) => {
      this.setState({data:data.sort((a,b) => b.order-a.order)})
    });


    

  }


  attributes(data,key){
    for(var i in data.attributes){
      if(data.attributes[i].key==key){
        return data.attributes[i].value
      }
    }
    return undefined;
  }
  sub_menu(sub){

    var sm=[]
        for(var i = 0 ; sub.length > i; i++){
          var menu = sub[i]

          sm.push(<Menu.Item key={"/model/"+menu.model+"/"+menu.type}>
                <Link to={"/model/"+menu.model+"/"+menu.type}><i className={"icon icon-"+menu.icon}/>
                <span>{menu.title}</span></Link>
              </Menu.Item>);
          
          
        }
        return sm
  }
  cvreate_menu(){

    const {themeType, navStyle, pathname} = this.props;
     var ms = []
      
      for(var i = 0 ; this.state.data.length > i; i++){
        var m = this.state.data[i]

        if(m.sub.length!=0){
          ms.push(<SubMenu key={m.id} className={this.getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className={"icon icon-"+m.icon}/>
                         <span>{m.title}</span></span>}>
                  {this.sub_menu(m.sub)}
                </SubMenu>)
        }else{

          ms.push(<Menu.Item key={"/model/"+m.model+"/"+m.type}>
                <Link to={"/model/"+m.model+"/"+m.type}><i className={"icon icon-"+m.icon}/>
                <span>{m.title}</span></Link>
              </Menu.Item>)
        }
      }
      return ms;
  }
  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="home">
                <Link to="/home"><i className="icon icon-widgets"/>
                  <span>Dashboard</span></Link>
              </Menu.Item>

              
             {this.cvreate_menu()}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

