import React from "react";
import socketIOClient from "socket.io-client";


export default class Api  {
  
  
  static URL='https://api.inciteai.us'
  //static URL='http://localhost:8080'
  static GetApiUrl(){

    if(process.env.API_URL==undefined){
      return Api.URL
    }else{
      return process.env.API_URL
    }
  }
  
  static socket = socketIOClient(Api.GetApiUrl());
  static Play(m,text){
    var audio = new Audio(Api.GetApiUrl()+'/ispeech/'+m+'/'+text)
    audio.play()
  }
  static Accion(model,accion,data){
      
      return fetch(Api.GetApiUrl()+"/accion/"+model+'/'+accion,{
        method: 'POST',
        
        body:JSON.stringify({data:data,user:localStorage.getItem('user_id')})
      })
      .then((response) => response.json())
                
  }
  static getImg(model,field,id){
    return Api.GetApiUrl()+'/images/'+model+'/'+field+'/'+id
  }
  static Menu(model,accion,data){
      
      return fetch(Api.GetApiUrl()+"/menu",{
        method: 'POST',
        
        body:JSON.stringify({token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json())
              
  }

  static Dashboard(){
      
      return fetch(Api.GetApiUrl()+"/dashboard",{
        method: 'POST',
        
        body:JSON.stringify({token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json())
              
  }

    static DashboardData(model,src){
      
      return fetch(Api.GetApiUrl()+"/dashboard/"+model+'/'+src,{
        method: 'POST',
        
        body:JSON.stringify({token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json())
              
  }


   static view(model,view,data){
      
      return fetch(Api.GetApiUrl()+"/view",{
        method: 'POST',
        
        body:JSON.stringify({data:data,model:model,view:view,token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json()) 
              
  }

  static Export(model,data){
      
      return fetch(Api.GetApiUrl()+"/export/"+model,{
        method: 'POST',
        
        body:JSON.stringify({...data,token:localStorage.getItem('user_id')})
      })
      .then((response) => response.json())
                
  }
  

    static Report(name,id){
      
      window.location.replace(Api.GetApiUrl()+"/report/"+name+"/"+id);
                
  }


  static Get(model,id){
      
      return fetch(Api.GetApiUrl()+"/get/"+model+'/'+id,{
        method: 'POST',
        
      })
      .then((response) => response.json())
                
  }



  static Save(model,data){
      
    return fetch(Api.GetApiUrl()+"/save/"+model,{
      method: 'POST',
      
      body:JSON.stringify({data:data,token:localStorage.getItem('user_id')})
    })
    .then((response) => response.json()) 

                
  }

  static UserInfo(){
      
    return fetch(Api.GetApiUrl()+"/userinfo",{
      method: 'POST',
      
      body:JSON.stringify({token:localStorage.getItem('user_id')})
    })
    .then((response) => response.json()) 

                
  }
  static Change(model,fun,data){
      
    return fetch(Api.GetApiUrl()+"/change/"+model+"/"+fun,{
          method: 'POST',
          
          body:JSON.stringify({data:data,token:localStorage.getItem('user_id')})
        })
    .then((response) => response.json()) 

                
  }


  static List(model,data={}){
      
      return fetch(Api.GetApiUrl()+"/list/"+model,{
                  method: 'POST',
            
                  body:JSON.stringify({...data,token:localStorage.getItem('user_id')})
                })
                .then((response) => response.json())
                .then((data) => {

                  return data                  
                }) 
                
  }
  static Soket(model,event){
    
    Api.socket.on(model, data => {
        console.log(data)
        event(data.data)
    });
  }

  static RemoveSoket(model){
    
    Api.socket.off(model);
  }
  static Save(model,data){

    return fetch(Api.GetApiUrl()+"/save/"+model,{
      method: 'POST',
      
      body:JSON.stringify({data:data,token:localStorage.getItem('user_id')})
    })
    .then((response) => response.json()) 
  }
}
